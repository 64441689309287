import React, {useState} from "react"
import axios from "axios"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import { CustomButton } from "../components/common/Button"
import Layout from "../components/Layout"
import styled from "styled-components"
import Seo from "../components/seo"
import Ask from "../assets/ask.svg"
import Inspiration from "../assets/inspiration.svg"
import Competitor from "../assets/competitor.svg"
import Outline from "../assets/outline.svg"
import Terms from "../assets/terms.svg"
import BeautifulEditor from "../assets/edit.svg"
import Headset from "../assets/headset.svg"
import Percentage from "../assets/percentage.svg"
import Refresh from "../assets/refresh.svg"
import CustomInput from "../components/common/CustomInput"
import { AiOutlineGlobal, AiOutlineMail, AiOutlineUser } from "react-icons/ai"

const WhyRobinizeOptions = [
  {
    name: "Competitors analysis",
    icon: <Competitor />,
    color: "#E2C016",
  },
  {
    name: "Inspiration",
    icon: <Inspiration />,
    color: "#18B58B",
  },
  {
    name: "Outline",
    icon: <Outline />,
    color: "#FC3760",
  },
  {
    name: "50 terms",
    icon: <Terms />,
    color: "#2C96F2",
  },
  {
    name: "People also ask",
    icon: <Ask />,
    color: "#FB7450",
  },
  {
    name: "Beautiful editor",
    icon: <BeautifulEditor />,
    color: "#B518A5",
  },
]

const Affiliate = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [webPage, setWebPage] = useState("")
  const [response, setResponse] = useState(null);
  const url = process.env.AFFILIATE_URL;

  const checkEmail = email => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return regex.test(email)
  }

  const submitAffiliate = () => {
    if (!checkEmail(email)) {
      setResponse({ type: "email", message: "Please enter a valid email" })
      setTimeout(() => {
        setResponse(null)
      }, 2000)
      return;
    }   
    const params = {
      params: {
        name: fullName,
        email: email,
        web_page: webPage
      },
    };

   axios
     .get(url, params)
     .then(() => {
       setResponse({ type: "success", message: "Request successfully sent. We will contact you soon." })
       setFullName("")
       setEmail("")
       setWebPage("")
     })
     .catch(error => {
       setResponse({
         type: "server",
         message:
           error.response.data.detail ||
           "Oops! Something went wrong. Please try again later.",
       })
       setTimeout(() => {
         setResponse(null)
       }, 2000)
     })    
  }

  return (
    <CustomThemeProvider>
      <Seo title={"Affiliate Partnership"} />
      <Layout>
        <HeadlineContainer>
          <Header>
            Become a <br />
            <Span>Robinize Affiliate Partner</Span>
          </Header>
          <HeaderParahraph>
            If you are growing your digital marketing and SEO business and want
            <br /> to generate extra income from your content, you can do it by
            helping <br /> your audience discover Robinize.
          </HeaderParahraph>
          <EngageContainer>
            <InputsContainer>
              <CustomInput
                value={fullName}
                onChange={e => setFullName(e.target.value)}
                icon={<AiOutlineUser />}
                type="text"
                placeHolder="Name and Surname"
              />
              {response?.type === "email" ? (
                <BadEmail error={true}>
                  {"Please enter a valid email"}
                </BadEmail>
              ) : (
                <CustomInput
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  icon={<AiOutlineMail />}
                  type="email"
                  placeHolder="E-mail"
                />
              )}
              <CustomInput
                value={webPage}
                onChange={e => setWebPage(e.target.value)}
                icon={<AiOutlineGlobal />}
                type="text"
                placeHolder="Web page"
              />
            </InputsContainer>
            {response?.type === "success" || response?.type === "server" ? (
              <BadEmail
                error={response?.type === "server"}
                height={"60px"}
                marginTop={0}
              >
                {response.message}
              </BadEmail>
            ) : (
              <Button
                disabled={
                  (fullName.length === 0 ||
                    webPage.length === 0 ||
                    email.length === 0) &&
                  !response
                }
                onClick={submitAffiliate}
              >
                Become a Robinize Partner
              </Button>
            )}
          </EngageContainer>
        </HeadlineContainer>
        <TestimonialsContainer>
          <TestimonialHeader>Our ideal partners are...</TestimonialHeader>
          <TestimonialContent>
            <TestimonialContentDivs>
              <TestimonialContentHeader>
                Digital agencies and SEO consultants
              </TestimonialContentHeader>
              <TestimonialContentParagraph>
                Earn rewards for assisting your clients in achieving success.
              </TestimonialContentParagraph>
            </TestimonialContentDivs>
            <TestimonialContentDivs>
              <TestimonialContentHeader>
                Influencers and Affiliate marketers
              </TestimonialContentHeader>
              <TestimonialContentParagraph>
                If you promote products professionally through content or advertisements, we got you covered!
              </TestimonialContentParagraph>
            </TestimonialContentDivs>
            <TestimonialContentDivs>
              <TestimonialContentHeader>
                Experts, Coaches or Mentors
              </TestimonialContentHeader>
              <TestimonialContentParagraph>
                Your audience will appreciate you introducing them to the tool that actually helps!
              </TestimonialContentParagraph>
            </TestimonialContentDivs>
          </TestimonialContent>
        </TestimonialsContainer>
        <HowProgramWorksContainer>
          <HowProgramWorksHeader>
            How Robinize Affiliate program works?
          </HowProgramWorksHeader>
          <HowProgramWorksContent>
            <HowProgramWorksChildContent>
              <Number>1</Number>
              <ChildContainer>
                <ChildHeader>Join the program</ChildHeader>
                <ChildParagraph>
                  Sign up to Robinize and generate an affiliate link
                </ChildParagraph>
              </ChildContainer>
            </HowProgramWorksChildContent>
            <HowProgramWorksChildContent>
              <Number>2</Number>
              <ChildContainer>
                <ChildHeader>Tell your community about Robinize</ChildHeader>
                <ChildParagraph>
                  Get inspiration and some useful materials here
                </ChildParagraph>
              </ChildContainer>
            </HowProgramWorksChildContent>
            <HowProgramWorksChildContent>
              <Number>3</Number>
              <ChildContainer>
                <ChildHeader>Receive bonuses</ChildHeader>
                <ChildParagraph>
                  Keep the funds in your own account or pay them out
                </ChildParagraph>
              </ChildContainer>
            </HowProgramWorksChildContent>
          </HowProgramWorksContent>
        </HowProgramWorksContainer>
        <WhyRobinizeContainer>
          <WhyRobinizeLeft>
            <WhyRobinizeHeader>Why Robinize?</WhyRobinizeHeader>
            <WhyRobinizeParagraph>
              Robinize is a SaaS solution built to save time and money for
              content writers and SEO agencies, by helping their content
              rank better in Google with the help of AI research
            </WhyRobinizeParagraph>
          </WhyRobinizeLeft>
          <WhyRobinizeRight>
            {WhyRobinizeOptions.map((item, index) => (
              <Item key={index}>
                <ItemImg>{item.icon}</ItemImg>
                <ItemName Color={item.color}>{item.name}</ItemName>
              </Item>
            ))}
          </WhyRobinizeRight>
        </WhyRobinizeContainer>
        <WhatYouGetContainer>
          <WhatYouGetHeader>
            What you get as a Robinize Affiliate Partner?
          </WhatYouGetHeader>
          <WhatYouGetContent>
            <WhatYouGetItem>
              <WhatYouGetIcon>
                <PercentageContainer>
                  <PercentageNumber>30</PercentageNumber>
                  <Percentage />
                </PercentageContainer>
              </WhatYouGetIcon>
              <WhatYouGetItemHeader>
                Great commision potential
              </WhatYouGetItemHeader>
              <WhatYouGetParagraph>of the net value</WhatYouGetParagraph>
            </WhatYouGetItem>
            <WhatYouGetItem>
              <WhatYouGetIcon>
                <Headset />
              </WhatYouGetIcon>
              <WhatYouGetItemHeader>
                A subscription with affiliate bonuses
              </WhatYouGetItemHeader>
              <WhatYouGetParagraph>
                Exchange commision for reports
              </WhatYouGetParagraph>
            </WhatYouGetItem>
            <WhatYouGetItem>
              <WhatYouGetIcon>
                <Refresh />
              </WhatYouGetIcon>
              <WhatYouGetItemHeader>Premium support</WhatYouGetItemHeader>
              <WhatYouGetParagraph>
                Customer care and guidance <br />+ valuable materials
              </WhatYouGetParagraph>
            </WhatYouGetItem>
          </WhatYouGetContent>
        </WhatYouGetContainer>
      </Layout>
    </CustomThemeProvider>
  )
}

export default Affiliate

const PercentageNumber = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 4rem;
  @media (max-width: 470px) {
    font-size: 3rem;
  }
`

const PercentageContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const HeadlineContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 90vh;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-height: 668px) {
    min-height: 700px;
  }
  &::before {
    border-radius: 50%;
    position: absolute;
    background: #1e2248;
    right: -1200px;
    left: -1200px;
    top: -1200px;
    content: "";
    bottom: 0;
  }
`
const Header = styled.h1`
  font-size: clamp(2rem, 3vw, 3.75rem);
  font-family: InterBold;
  width: 40%;
  margin: 0;
  z-index: 1;
  @media (max-width: 768px) {
    font-size: clamp(2rem, 3vw, 4.75rem);
    width: 80%;
  }
`

const Span = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const HeaderParahraph = styled.p`
  font-family: Inter500;
  font-size: clamp(0.8rem, 2vw, 1.25rem);
  line-height: 1.4;
  max-width: 850px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 1;
  @media (max-width: 1024px) {
    font-size: clamp(0.8rem, 2vw, 1rem);
    padding-bottom: 0;
  }
`
const Button = styled(CustomButton)`
  width: 340px;
  padding: 15px 0px;
  font-family: Inter500;
  font-size: 1.125rem;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 0.3s ease-out;
  will-change: filter;
  border-radius: 8px;
  margin-top: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    filter: ${({ disabled }) => (disabled ? "brightness(1)" : "brightness(1.1)")}; 
  }
`
const TestimonialsContainer = styled.div`
  height: auto;
  min-height: 60vh;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  text-align: center;
  color: #092045;
`
const TestimonialHeader = styled.h1`
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: InterBold;
  color: #092045;
  @media (max-width: 470px) {
    font-size: 2rem;
    padding-top: 30px;
  }
`
const TestimonialContent = styled.div`
  display: flex;
  width: 80%;
  gap: 10px;
  margin: 50px 0;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const TestimonialContentDivs = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  padding: 40px 20px;
  border-radius: 10px;
  width: 25%;
  @media (max-width: 768px) {
    padding: 20px 10px;
    width: 100%;
  }
`

const TestimonialContentHeader = styled.h3`
  font-size: clamp(1rem, 3vw, 1.6rem);
  font-family: InterBold;
  padding: 0px 20px;
  @media (max-width: 470px) {
    font-size: clamp(1.6rem, 3vw, 1.8rem);
  }
`

const TestimonialContentParagraph = styled.p`
  font-family: Inter500;
  font-size: clamp(0.8rem, 2vw, 1.25rem);
`

const HowProgramWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 50vh;
  background: #1e2248;
  text-align: center;
  margin: 0 auto;
  align-items: center;
`

const HowProgramWorksHeader = styled.h3`
  padding-left: 10px;
  padding-right: 10px;
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: InterBold;
`

const HowProgramWorksContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15vw 0;
  }
`

const HowProgramWorksChildContent = styled.div`
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`

const Number = styled.div`
  font-size: 8rem;
  font-family: InterBold;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.primary};
  @media (max-width: 470px) {
    font-size: 6rem;
  }
`

const ChildContainer = styled.div`
  height: fit-content;
`

const ChildHeader = styled.h3`
  font-size: clamp(1rem, 2vw, 1.6rem);
  font-family: InterBold;
  margin-top: 0px;
`

const ChildParagraph = styled.p`
  font-size: clamp(1rem, 2vw, 1.3rem);
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 0px;
`
const WhyRobinizeContainer = styled.div`
  height: auto;
  min-height: 60vh;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
  }
`

const WhyRobinizeLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  min-width: 200px;
`

const WhyRobinizeHeader = styled.h3`
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: InterBold;
  color: #272a39;
`

const WhyRobinizeParagraph = styled.p`
  font-size: clamp(1rem, 3vw, 1.25rem);
  min-width: 200px;
  padding-right: 30%;
  color: ${({ theme }) => theme.colors.gray};
`

const WhyRobinizeRight = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: auto 0;
  @media (max-width: 470px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
  }
`

const Item = styled.div`
  border: 1px solid #0000000d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
`

const ItemName = styled.h1`
  font-size: clamp(1rem, 3vw, 1.25rem);
  font-family: InterBold;
  color: ${({ Color }) => Color};
`

const ItemImg = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`
const WhatYouGetContainer = styled.div`
  height: auto;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    align-items: start;
    padding-left: 40px;
  }
  @media (max-width: 470px) {
    align-items: start;
    padding-left: 30px;
  }
`

const WhatYouGetContent = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const WhatYouGetItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 50px;
`

const WhatYouGetIcon = styled.div`
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
  @media (max-width: 470px) {
    height: 40px;
    width: 40px;
  }
`

const WhatYouGetHeader = styled.h3`
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: InterBold;
`
const WhatYouGetItemHeader = styled.div`
  font-size: clamp(1rem, 3vw, 1.7rem);
`

const WhatYouGetParagraph = styled.p`
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 5px;
`

const EngageContainer = styled.div`
  height: 30vh;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @media (max-width: 1020px) {
    gap: 20px;
  }
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  gap: 20px;
  @media (max-width: 1203px) {
    gap: 10px;
  }
`

const BadEmail = styled.h4`
  position: relative;
  width: 100%;
  justify-content: center;
  color: ${({ theme, error }) =>
    error ? theme.colors.accent : theme.colors.primary};
  display: flex;
  align-items: center;
  height: ${({ height }) => height || "40px"};
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`