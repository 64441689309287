import React from "react"
import styled from "styled-components"

const CustomInput = ({ placeHolder, type, icon, Height, onChange, value }) => {
  return (
    <NewsLetterWrapper>
      <Search>
        <IconInput>{icon}</IconInput>
        <NewsLetterInput
          Height={Height}
          type={type}
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
        ></NewsLetterInput>
      </Search>
    </NewsLetterWrapper>
  )
}

export default CustomInput

const NewsLetterWrapper = styled.div`
  width: 100%;
  height: 40px;

  @media (max-width: 1203px) {
    width: 100%;
  }
`

const NewsLetterInput = styled.input`
  width: 100%;
  height: ${({ Height }) => (Height ? Height : "40px")};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px 55px;
  font-family: inherit;
  font-size: inherit;
  border: none;
  text-decoration: none;
  outline: none;
  @media (max-width: 1203px) {
    margin-right: 0;
  }
`

const Search = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1203px) {
    width: 100%;
  }
`

export const IconInput = styled.div`
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  color: silver;
  size: 1.5em;
`
